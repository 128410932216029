/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-computed-properties-in-native-classes */
import Model, { attr, belongsTo } from '@ember-data/model';
import { getOwner } from '@ember/application';
import { computed } from '@ember/object';
import { and, bool, equal, not, or, alias, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { compare } from '@ember/utils';
import { copy } from 'ember-copy';
import { fragment } from 'ember-data-model-fragments/attributes';
import { get } from 'embercom/lib/ajax';
import nonconcurrentAjax from 'embercom/lib/nonconcurrent-ajax';
import {
  BUTTON_BOT_TYPE,
  CONVERSATION_STARTED_TARGET,
  customBotDeliveryChannels,
  INBOUND_BOT_TYPE,
  NO_TRIGGER_TARGET,
  OUTBOUND_BOT_TYPE,
  TRIGGERABLE_BOT_TYPE,
  USER_INACTIVITY_TARGET,
  ADMIN_INACTIVITY_TARGET,
  NEW_PHONE_CALL_TARGET,
  CONVERSATION_ASSIGNMENT_CHANGED_TARGET,
  AI_AGENT_WORKFLOW_TARGET,
} from 'embercom/lib/operator/custom-bots/constants';
import Admin from 'embercom/models/admin';
import { objectTypes, states } from 'embercom/models/data/matching-system/matching-constants';
import MessageConstants from 'embercom/models/data/messages/message-constants';
import ContentDependency from 'embercom/models/matching-system/content-dependency';
import StateProperties from 'embercom/models/mixins/common/states/state-properties';
import Workflowable from 'embercom/models/mixins/operator/workflowable';
import buttonCustomBotEditConfigGenerator from 'embercom/objects/content-editor/configuration-generators/button-custom-bot';
import customBotEditConfigGenerator from 'embercom/objects/content-editor/configuration-generators/custom-bot';
import inboundCustomBotEditConfigGenerator from 'embercom/objects/content-editor/configuration-generators/inbound-custom-bot';
import triggerableCustomBotEditConfigGenerator from 'embercom/objects/content-editor/configuration-generators/triggerable-custom-bot';
import workflowEditConfigGenerator from 'embercom/objects/content-editor/configuration-generators/workflow';
import { CUSTOM_BOT_CONFIG_CLASSES } from 'embercom/objects/operator/configuration/configuration';
import Validations from 'embercom/validations/custom-bot';

export const COMPOSER_SUGGESTION_LAYOUT = 'composerSuggestionLayout';
export const CONVERSATION_LAYOUT = 'conversationLayout';

class RootWorkflow {
  dependency;
  targetChannels;

  constructor(json) {
    this.dependency = new ContentDependency(json);
    this.targetChannels = json.target_channels;
  }
}

export default Model.extend(Workflowable, Validations, StateProperties, {
  appService: service(),
  store: service(),
  intl: service(),
  finOptInService: service(),
  app: readOnly('appService.app'),
  availableTags: readOnly('app.tags.[]'),
  visualBuilderObject: belongsTo('operator/visual-builder/workflow', { async: false }),

  hasUnsavedChanges: or('hasDirtyAttributes', 'visualBuilderObject.hasUnsavedChanges'),

  rollbackAttributes() {
    this._super(...arguments);
    this.visualBuilderObject?.rollbackAttributes();
  },

  isCustomBot: true,
  autoSaveDisabled: true,
  config: computed('target', function () {
    if (!CUSTOM_BOT_CONFIG_CLASSES[this.target]) {
      throw new Error(
        `Cannot resolve path configuration class for custom bot target=${this.target}`,
      );
    }

    return new CUSTOM_BOT_CONFIG_CLASSES[this.target](this);
  }),

  editorConfig() {
    let container = getOwner(this);
    if (this.canUseVisualBuilderEditor) {
      return workflowEditConfigGenerator({ container, customBot: this });
    } else if (this.isInbound) {
      return inboundCustomBotEditConfigGenerator({ container, customBot: this });
    } else if (this.isButton) {
      return buttonCustomBotEditConfigGenerator({ container, customBot: this });
    } else if (this.isTriggerable) {
      return triggerableCustomBotEditConfigGenerator({ container, customBot: this });
    } else {
      return customBotEditConfigGenerator({ container, customBot: this });
    }
  },

  contentType: 'custom-bot',
  title: attr('string'),
  target: attr('string'),
  entityType: attr('number'),
  botData: fragment('operator/custom-bots/custom-bot-data'),
  order: attr('number'),
  state: attr('string'),
  showUntil: attr('string'),
  type: attr('string'),
  composerSuggestions: attr(),
  rulesetId: attr('number'),
  latestWorkflowInstanceId: attr('number'),

  editorLayout: computed('isInbound', function () {
    if (this.isInbound) {
      return COMPOSER_SUGGESTION_LAYOUT;
    } else {
      return CONVERSATION_LAYOUT;
    }
  }),

  triggerPredicateGroup: fragment('predicates/predicate-group'),

  isInbound: equal('type', INBOUND_BOT_TYPE),
  isOutbound: equal('type', OUTBOUND_BOT_TYPE),
  isButton: equal('type', BUTTON_BOT_TYPE),
  isTriggerable: equal('type', TRIGGERABLE_BOT_TYPE),

  canUseOmnichannel: computed('target', function () {
    return this.isTriggerable && !this.isNoTrigger;
  }),

  isNoTrigger: equal('target', NO_TRIGGER_TARGET),
  isFirstMessageTrigger: equal('target', CONVERSATION_STARTED_TARGET),
  isInactivityTrigger: computed('target', function () {
    return [USER_INACTIVITY_TARGET, ADMIN_INACTIVITY_TARGET].includes(this.target);
  }),
  isPhoneCallTrigger: equal('target', NEW_PHONE_CALL_TARGET),
  isConversationAssignmentChangedTrigger: equal('target', CONVERSATION_ASSIGNMENT_CHANGED_TARGET),
  isAiAgentWorkflowTrigger: equal('target', AI_AGENT_WORKFLOW_TARGET),

  isInboundOrButton: or('isInbound', 'isButton'),
  isInboundOrButtonOrOutbound: or('isInbound', 'isButton', 'isOutbound'),

  templateId: attr('number'),
  lastUpdatedAt: attr('date'),
  lastUpdatedById: attr('number'),
  lastUpdatedBy: computed('lastUpdatedById', function () {
    return Admin.peekAndMaybeLoad(this.store, this.lastUpdatedById);
  }),

  hasTemplate: bool('templateId'),
  hasNoTemplate: not('hasTemplate'),

  isNewCustomBot: false,
  isNotNewCustomBot: not('isNewCustomBot'),

  isValid: or('isNotLive', 'validations.isValid'),
  isLive: equal('state', 'live'),
  isStopped: equal('state', 'stopped'),
  isDraft: equal('state', 'draft'),
  notDraft: not('isDraft'),
  canSave: and('isValid', 'isNotUploading'),
  canActivate: and('validations.isValid', 'isNotUploading'),
  canUseVisualBuilderEditor: computed('paths.length', 'visualBuilderObject', function () {
    return !this.paths.length && this.visualBuilderObject;
  }),
  validLocalVariableIdentifiersUsedInWorkflow: readOnly(
    'visualBuilderObject.validations.attrs.localVariablesExist.isValid',
  ),

  shouldShowOptInCopyForAiAnswers: computed('useAiAnswers', function () {
    return (
      this.useAiAnswers &&
      this.finOptInService.bypassFinOptInModal &&
      !this.finOptInService.hasOptedInToFin
    );
  }),
  useAiAnswers: readOnly('visualBuilderObject.useAiAnswers'),

  shouldShowOptInCopyForAiCategorization: computed('useAiCategorization', function () {
    return this.useAiCategorization && !this.app.canUseExternalAi;
  }),
  useAiCategorization: readOnly('visualBuilderObject.useAiCategorization'),

  shouldShowOptInCopyForAiAutoTranslation: computed('botData.multilingualEnabled', function () {
    return this.botData?.multilingualEnabled && !this.app.canUseExternalAi;
  }),

  previewDisabledMessage: computed('intl.locale', 'canActivate', 'hasUnsavedChanges', function () {
    if (!this.canActivate) {
      return this.intl.t('operator.custom-bot.editor.header.errors-in-bot');
    }
    if (this.hasUnsavedChanges) {
      return this.intl.t('operator.custom-bot.editor.header.please-save');
    }

    if (this.visualBuilderObject?.isCustomerFacing === false) {
      return this.intl.t('operator.custom-bot.editor.header.cannot-preview-background');
    }
  }),

  canPreview: not('previewDisabledMessage'),

  preferredDevices: attr('array'),
  targetChannels: attr('array'),

  deliveryChannel: computed('preferredDevices.[]', function () {
    return customBotDeliveryChannels.find((channel) => {
      return compare(channel.preferredDevices.sort(), this.preferredDevices.sort()) === 0;
    });
  }),

  validationKeys: ['validations.attrs.paths', 'validations.attrs.firstPathConstraints'],

  validationMessages: computed(
    'intl.locale',
    'validations.messages.[]',
    'customBot.isUploading',
    function () {
      let errorMessages = [];
      let validationKeys = this.validationKeys;
      if (this.get('customBot.isUploading')) {
        errorMessages.push(this.intl.t('operator.custom-bot.editor.header.upload-error-message'));
      }

      validationKeys.forEach((validationKey) => {
        if (this.get(`${validationKey}.isInvalid`)) {
          let message = this._formatValidationMessages(
            validationKey,
            this.get(`${validationKey}.messages`),
          );
          errorMessages.push(message);
        }
      });

      return errorMessages.flat();
    },
  ),

  validationFailureMessage: computed('validationMessages.[]', function () {
    return this.validationMessages.join('. ');
  }),

  previewConfiguration: computed(
    'analyticsEventObjectLabel',
    'intersectionPreviewMode',
    'composerSuggestions',
    'title',
    function () {
      return {
        previewTitle: this.title,
        intersectionMode: this.intersectionPreviewMode,
        analyticsEventObjectLabel: this.analyticsEventObjectLabel,
        composerSuggestions: this.getComposerSuggestions(),
      };
    },
  ),

  intersectionPreviewMode: computed('isInbound', function () {
    if (this.isInbound) {
      return 'inbound-custom-bot';
    } else {
      return 'outbound-custom-bot';
    }
  }),

  analyticsData: computed('id', 'isNewCustomBot', 'firstStep.id', function () {
    return {
      object: 'bot-auto-message',
      context: this.isNewCustomBot ? 'new-workflow' : 'existing-workflow',
      custom_bot_id: this.id,
      workflow_first_step_id: this.get('firstStep.id'),
    };
  }),

  analyticsEventObjectLabel: computed('target', 'isInbound', function () {
    if (this.target === 'everyone') {
      return 'custom_bot_bob';
    } else if (this.target === 'user') {
      return this.isInbound ? 'custom_bot_user_inbound' : 'custom_bot_user_outbound';
    } else if (this.target === 'no-trigger' || this.target === 'conversation-started') {
      return 'custom_bot_triggerable';
    } else {
      return this.isInbound ? 'custom_bot_visitor_inbound' : 'custom_bot_visitor_outbound';
    }
  }),

  allowedUserRoles: computed('isPhoneCallTrigger', 'isTriggerable', function () {
    if (this.isPhoneCallTrigger || this.isTriggerable) {
      return ['user', 'lead'];
    } else {
      return ['user', 'lead', 'visitor'];
    }
  }),

  messageVariationForMessengerPreview: computed(
    'lastStep.controls.[]',
    'firstStep.blocks.[]',
    function () {
      let messageVariation = this.store.createRecord('message-variation');
      let replyOptions = this.get('lastStep.controls').mapBy('buttonLabel');
      messageVariation.setProperties({
        blocks: copy(this.get('firstStep.blocks')),
        from: this.get('app.operatorBot'),
        isInApp: true,
        messageStyle: MessageConstants.chat,
        replyOptions,
      });
      return messageVariation;
    },
  ),

  compatibleChannels: readOnly('visualBuilderObject.supportedChannels', function () {
    return this.visualBuilderObject.supportedChannels;
  }),

  inactivityDuration: alias('botData.inactivityDuration'),

  description: attr('string'),

  matchPercentage: alias('botData.matchPercentage'),

  showFinInvolvementStat: computed('isAiAgentWorkflowTrigger', function () {
    return (
      !this.isAiAgentWorkflowTrigger &&
      this.app.canUseFeature('rd-services-view-fin-involvement-stat')
    );
  }),

  _formatValidationMessages(key, messages) {
    if (key.includes('paths') || key.includes('firstPathConstraints')) {
      return this.intl.toString('operator.custom-bot.editor.header.finish-designing');
    }

    if (key.includes('messengerTrigger')) {
      return this.intl.t('operator.custom-bot.editor.header.select-to-launch');
    }

    return messages;
  },

  getComposerSuggestions() {
    if (!this.composerSuggestions) {
      return null;
    }
    return this.composerSuggestions;
  },

  beforeSave() {
    this.paths.invoke('ensureStepsAreLinkedCorrectly');

    if (this.rulesetId) {
      let ruleset = this.store.peekRecord('matching-system/ruleset', this.rulesetId);
      this.title = ruleset?.clientData?.title ?? '';
    }
  },

  activate() {
    return this._post(`/ember/operator_custom_bots/${this.id}/activate`).then((response) => {
      this._pushPayloadToStore(response);
    });
  },

  deactivate() {
    return this._post(`/ember/operator_custom_bots/${this.id}/deactivate`).then((response) => {
      this._pushPayloadToStore(response);
    });
  },

  async loadDependentObjects() {
    if (this.isNoTrigger) {
      let dependencies = await get(`/ember/operator_custom_bots/${this.id}/dependent_objects`, {
        app_id: this.get('app.id'),
      });

      return dependencies.map((dependency) => {
        return new ContentDependency({
          entity_id: dependency.id,
          entity_type: objectTypes.triggerableCustomBot,
          title: dependency.title,
          ruleset_id: dependency.ruleset_id,
          state: dependency.live ? states.live : states.draft, // we only need values for 'live' and 'not live'
        });
      });
    }
    return [];
  },

  async loadRootWorkflows() {
    if (this.isNoTrigger) {
      let rootWorkflows = await get(`/ember/operator_workflows/root_workflows`, {
        app_id: this.get('app.id'),
        entity_type: objectTypes.triggerableCustomBot,
        entity_id: this.id,
      });

      return rootWorkflows.map((workflow) => {
        return new RootWorkflow(workflow);
      });
    }
    return [];
  },

  async loadUserVisibilityChangingBots() {
    if (this.isNoTrigger) {
      let userVisibilityChangingBots = await get(
        `/ember/operator_custom_bots/${this.id}/user_visibility_changing_bots`,
        {
          app_id: this.get('app.id'),
          user_visible: !!this.visualBuilderObject?.isCustomerFacing,
        },
      );

      return userVisibilityChangingBots;
    }
    return [];
  },

  _post(url, additionalData = {}) {
    return this._request(url, 'POST', additionalData);
  },

  _request(url, requestType = 'POST', additionalData = {}) {
    let data = Object.assign(additionalData, {
      id: this.id,
      app_id: this.get('app.id'),
      admin_id: this.get('app.currentAdmin.id'),
    });
    if (requestType === 'POST') {
      data = JSON.stringify(data);
    }
    return nonconcurrentAjax(this, {
      url,
      type: requestType,
      data,
    });
  },

  _pushPayloadToStore(response) {
    let serializer = this.store.serializerFor('operator/custom-bot');
    let model = this.store.modelFor('operator/custom-bot');
    let serializedResponse = serializer.normalize(model, response);
    serializedResponse.id = response.id;
    this.store.push(serializedResponse);
  },
});
