/* RESPONSIBLE TEAM: team-workflows */
import { intersection } from 'underscore';
import Model, { attr, belongsTo, hasMany, type SyncHasMany } from '@ember-data/model';
import Step from 'embercom/models/operator/visual-builder/step';
import generateUUID from 'embercom/lib/uuid-generator';
import { validator, buildValidations } from 'ember-cp-validations';
import { characterIndex } from 'embercom/helpers/character-index';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type ConnectionPoint from './connection-point';

/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import { not, readOnly } from '@ember/object/computed';
import type Workflow from './workflow';
import type Edge from './edge';
import type EditorConfig from 'embercom/objects/visual-builder/configuration/editor';
import type IntlService from 'embercom/services/intl';
import type WorkflowEditorService from 'embercom/services/workflow-editor-service';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';

const Validations = buildValidations<{ steps: any; inwardEdges: any; outwardConnectionPoint: any }>(
  {
    steps: [
      validator('visual-builder/chat-message-inbound-bot-mobile-constraints', {
        disabled: not('model.isStart'),
      }),
      validator('has-many'),
      validator('presence', {
        presence: true,
        disabled: readOnly('model.isPlaceholder') && not('model.isStart'),
      }),
      validator('visual-builder/phone-workflow-step-constraints'),
    ],
    inwardEdges: [
      validator('visual-builder/no-orphan-paths', {
        message: 'operator.workflows.visual-builder.validations.orphan-path-is-not-visible',
        disabled: readOnly('model.isStart'),
      }),
    ],
    localVariablesExist: [validator('workflow-local-variables-exist')],
  },
);

export default class Group extends Model.extend(Validations) {
  @attr('boolean') declare isStart: boolean;
  @attr('string') declare title: string;
  @hasMany('operator/visual-builder/step', { polymorphic: true, async: false, inverse: 'group' })
  declare steps: SyncHasMany<Step>;
  @hasMany('operator/visual-builder/edge', { async: false, inverse: 'toGroup' })
  declare inwardEdges: SyncHasMany<Edge>;
  @belongsTo('operator/visual-builder/workflow', { async: false, inverse: 'groups' })
  declare workflow: Workflow;

  @belongsTo('operator/visual-builder/connection-point', { async: false, inverse: 'group' })
  declare outwardConnectionPoint: ConnectionPoint | null;

  @readOnly('workflow.editorConfig')
  declare editorConfig?: EditorConfig;

  layoutColumnIndex = 0;
  @attr('string') placeholderName?: string;

  @service declare intl: IntlService;
  @service declare workflowEditorService: WorkflowEditorService;
  @service declare appService: any;

  get analyticsData() {
    return {
      ...(this.workflow?.analyticsData ?? {}),
      object: 'visual_builder_path',
      path_id: this.id,
      is_start: this.isStart,
    };
  }

  @computed('workflow.id', 'editorConfig')
  get pathConfig() {
    return this.editorConfig?.generatePathConfig({
      path: this,
      editorState: this.workflowEditorService.getState(this.workflow.id) as EditorState,
    });
  }

  @computed('workflow.groups.length')
  get titlePrefix() {
    return `${characterIndex([this.workflow?.groups.indexOf(this)])}.`;
  }

  @computed('steps.@each.supportedChannels')
  get supportedChannels() {
    let supportedChannels = this.steps.map((step) => step.supportedChannels);
    return intersection(...supportedChannels);
  }

  get fullPathTitle() {
    return `${this.titlePrefix} ${
      this.title || this.intl.t('operator.workflows.visual-builder.path')
    }`;
  }

  get isEnding() {
    return this.outwardConnectionPoint?.isTerminal || this.steps.lastObject?.isTerminal;
  }

  get isExpanded(): boolean {
    return this._expanded ?? this.isStart; // starting group is expanded by default
  }

  get hasGroupEndingStep() {
    return !!this.steps.lastObject?.isGroupEnding;
  }

  get isPlaceholder() {
    // possibly brittle check, but this is only true for place holder nodes currently
    return this.steps.length === 0;
  }

  @computed('steps.length')
  get isCustomerFacing() {
    return this.steps.isAny('isCustomerFacing');
  }

  @computed('steps.@each.useAiAnswers')
  get useAiAnswers() {
    return this.steps.isAny('useAiAnswers');
  }

  @computed('steps.@each.type')
  get useAiCategorization() {
    return this.steps.any(
      (step: Step) => step.type === 'operator/visual-builder/step/classify-conversation-attribute',
    );
  }

  @computed('steps.{length,@each.localVariableIdentifiersUsed}')
  get localVariableIdentifiersUsed(): string[] {
    return this.steps
      .map((step) => step.localVariableIdentifiersUsed)
      .reduce((a, b) => a.concat(b), []);
  }

  findAllOutwardConnectionPoints(): ConnectionPoint[] {
    let groupOutwardConnectionPoints =
      this.steps.reduce(
        (points, step) => points.concat(step.outwardConnectionPoints.toArray()),
        [] as ConnectionPoint[],
      ) || [];

    if (this.outwardConnectionPoint) {
      groupOutwardConnectionPoints.push(this.outwardConnectionPoint);
    }

    return groupOutwardConnectionPoints;
  }

  static sanitizePayload(groupPayload: { steps?: object[] }) {
    groupPayload.steps?.forEach((step) => Step.sanitizePayload(step));
  }

  @computed('hasDirtyAttributes', 'steps.@each.hasUnsavedChanges')
  get hasUnsavedChanges() {
    return this.hasDirtyAttributes || this.steps.isAny('hasUnsavedChanges');
  }

  rollbackAttributes() {
    let steps = this.steps.toArray();
    steps.forEach((step) => step.rollbackAttributes());
    super.rollbackAttributes();
  }

  toggleExpand(value?: boolean) {
    this._expanded = value ?? !this.isExpanded;
  }

  // private
  @attr('string', { defaultValue: () => generateUUID() }) declare _uuid: string;
  @tracked _expanded?: boolean;
}
