/* RESPONSIBLE TEAM: team-ai-agent */
import { buildValidations, validator } from 'ember-cp-validations';
import Step, { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import { attr } from '@ember-data/model';
import type Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';
// eslint-disable-next-line no-restricted-imports
import { getApp } from 'embercom/lib/container-lookup';
/* eslint-disable ember/no-computed-properties-in-native-classes */
import { not } from '@ember/object/computed';
import { computed } from '@ember/object';
import { resolutionBotBillingFeature } from 'embercom/components/operator/answer-bot/billing-helper';
import type ConnectionPoint from '../connection-point';
import type ContentImportService from 'embercom/services/content-import-service';
import { type AiContentLibrarySummary } from 'embercom/services/content-import-service';
import { AiAnswersState } from 'embercom/lib/operator/fin/types';
import { tracked } from '@glimmer/tracking';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';
import { type Block } from '@intercom/interblocks.ts';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import type HardResolutionWorkflow from 'embercom/models/operator/fin/hard-resolution-workflow';
import type InactivityWorkflow from 'embercom/models/operator/fin/inactivity-workflow';

const Validations = buildValidations({
  outwardConnectionPoints: [validator('has-many')],
  inactivityTimer: [
    validator('number', {
      allowNone: true,
      integer: true,
      gte: 60,
      lte: 180 * 24 * 60 * 60, // 180 days in seconds
      messageKey: 'operator.workflows.visual-builder.validations.fin.timer',
    }),
  ],
  hasAiContentReadyForFin: {
    descriptionKey: 'ai-agent.profiles.validation-errors.ai-content-not-ready',
    validators: [
      validator('fin-has-ai-content-ready', {
        disabled: not('model.useAiAnswers'),
      }),
    ],
  },
  autoCloseFollowUpMessages: {
    validators: [validator('has-many')],
  },
  hardResolutionWorkflow: {
    validators: [validator('belongs-to')],
  },
  inactivityWorkflow: {
    validators: [validator('belongs-to')],
  },
  emailSetupValid: [
    validator('ai-agent-email-setup-valid', {
      disabled: not('model.validateEmailSetup'),
      dependentKeys: ['app.dkim_settings.[]', 'app.customEmailAddresses.[]'],
    }),
  ],
});

export default class Fin extends Step.extend(Validations) {
  @attr('boolean') declare useCustomAnswers: boolean;
  @attr('string') declare botMode: 'looping' | 'one_time' | 'bot_only';
  @attr('boolean') declare useFinInactivitySettings: boolean;
  @attr('number') declare inactivityTimer: number;
  @attr('boolean') declare autoCloseWithAnswer: boolean;
  @attr('boolean') declare autoCloseNoAnswer: boolean;
  @attr('boolean') declare autoCloseNoQuestion: boolean;
  @attr('boolean') declare csatEnabled: boolean;
  @attr('boolean') declare csatEnabledForHardResolution: boolean;
  @attr('boolean') declare csatEnabledForSoftResolution: boolean;
  @attr('number') declare csatBlockUpdateAfter?: number;
  @attr('boolean') declare preHandoverAnswerEnabled: boolean;
  @fragment('operator/fin/hard-resolution-workflow')
  declare hardResolutionWorkflow?: HardResolutionWorkflow | null;
  @fragment('operator/fin/inactivity-workflow')
  declare inactivityWorkflow?: InactivityWorkflow | null;
  @fragmentArray('operator/fin/auto-close-message') declare autoCloseFollowUpMessages: [Block[]];

  minInactivityDuration = 60;
  maxInactivityDuration = 180 * 24 * 60 * 60; // 180 days in seconds

  get supportedChannels() {
    return SUPPORTED_CHANNELS.fin;
  }

  @service declare contentImportService: ContentImportService; // needed for validation
  @service declare contentEditorService: $TSFixMe;

  @tracked aiContentLibrarySummary: AiContentLibrarySummary = {};
  // setting the initial value to -1 to pass validation while fetching answers
  @tracked customAnswerCount = -1;

  get app() {
    return this.appService.app;
  }

  @computed(
    'app.{dkim_settings.[],customEmailAddresses.[]}',
    'contentEditorService.activeObject.targetChannels.[]',
  )
  get validateEmailSetup() {
    return this.contentEditorService.activeObject?.targetChannels?.includes('email');
  }

  get preHandoverAnswerAllowed() {
    return this.useAiAnswers && this.botMode !== 'bot_only';
  }

  static get supportedContexts() {
    return [TargetContext.Conversation, TargetContext.CustomerTicket];
  }

  get isAnyCSATSettingEnabled() {
    return this.csatEnabledForHardResolution || this.csatEnabledForSoftResolution;
  }

  static get isStepGroupEnding() {
    return true;
  }

  static get canBeCustomerFacing() {
    return IsCustomerFacing.Yes;
  }

  static get requiredBillingFeature() {
    let app = getApp();

    return resolutionBotBillingFeature(app);
  }

  static createNewStep(store: Store): Fin {
    let outwardConnectionPoints = [
      store.createRecord('operator/visual-builder/connection-point', { type: 'fin_triage' }),
    ] as ConnectionPoint[];

    let app = getApp();

    return store.createRecord('operator/visual-builder/step/fin', {
      type: 'operator/visual-builder/step/fin',
      outwardConnectionPoints,
      useAiAnswers: this.canUseAiAnswers(app) && !app.finBlockedInTestApp,
      useCustomAnswers: app.hasAnswerBot,
      botMode: 'looping',
      useFinInactivitySettings: true,
      inactivityTimer: 180,
      autoCloseWithAnswer: true,
      autoCloseNoAnswer: false,
      autoCloseNoQuestion: true,
      autoCloseFollowUpMessages: [],
      preHandoverAnswerEnabled: this.canUseAiAnswers(app) && !app.finBlockedInTestApp,
    });
  }

  static canUseAiAnswers(app: { aiAnswersState: AiAnswersState }) {
    return [
      AiAnswersState.active,
      AiAnswersState.trialNotStarted,
      AiAnswersState.trialStarted,
    ].includes(app.aiAnswersState);
  }
}
